import styles from './Header2.module.scss';
import logo from '../../assets/logo.png';
import search from '../../assets/search.png';
import hamburger from '../../assets/header/hamburger.png';
import basket from '../../assets/header/basket.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import SearchModal from '../SearchModal/SearchModal';
import { isDesktop } from 'react-device-detect';
import { loginCheck, logout } from '../../auth/auth';
import KakaoBanner from '../../components/KakaoBanner/KakaoBanner';
import { loginConfirm, loginConfirmRurl } from '../../common/loginConfirm';
import SideMenuMobile from '../../mobileComponents/SideMenuMobile/SideMenuMobile';
import global from '../../assets/header/global.png';

function Header2(props: any) {
  const location = useLocation();
  const [isSmallType, setIsSmallType] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  const movingBarRef = useRef<HTMLDivElement>(null);
  function handleNavigationBarMove(
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) {
    if (!movingBarRef.current) return;
    movingBarRef.current.style.left = e.currentTarget.offsetLeft + 'px';
    movingBarRef.current.style.width = e.currentTarget.offsetWidth + 'px';
  }
  function handleNavigationBarHide(
    e: React.MouseEvent<HTMLUListElement, MouseEvent>,
  ) {
    if (!movingBarRef.current) return;
    movingBarRef.current.style.width = '0px';
  }
  function handleLogout() {
    logout();
    window.location.href = '/';
  }
  const handleScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY > 50) setIsSmallType(true);
        else setIsSmallType(false);
      }, 200),
    [],
  );
  useEffect(() => {
    if (!isDesktop) return;
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    if (loginCheck()) setIsLogin(true);
    else setIsLogin(false);
  }, []);
  const [mobileSideOpen, setMobileSideOpen] = useState(false);
  const [languageSelectOpen, setLanguageSelectOpen] = useState(false);
  return (
    <>
      {isDesktop ? (
        <>
          <div
            className={`${styles.heighter}`}
            style={{ height: '131px' }}
          ></div>
          <div
            className={`${styles.container} ${
              isSmallType ? styles.tiny : ''
            } container`}
          >
            <div className={styles.header}>
              <img
                src={logo}
                alt="logo"
                className={styles.logo}
                onClick={() => navigate('/')}
              />
              <ul className={styles.btns}>
                {!isLogin && (
                  <li>
                    <Link to="/non-myorder">비회원 주문 조회</Link>
                  </li>
                )}
                <li>
                  <Link to="/basket">장바구니</Link>
                </li>
                <li>
                  {!isLogin ? (
                    <Link
                      to={`/login?rurl=${location.pathname + location.search}`}
                    >
                      로그인
                    </Link>
                  ) : (
                    <a href="#none" onClick={handleLogout}>
                      로그아웃
                    </a>
                  )}
                </li>
                <li>
                  {!isLogin ? (
                    <Link to="/join">회원가입</Link>
                  ) : (
                    <Link to="/myPage">마이페이지</Link>
                  )}
                </li>
              </ul>
              {/* <div
                className={styles.globalLanguageBtn}
                onClick={() => {
                  if (languageSelectOpen) setLanguageSelectOpen(false);
                  else setLanguageSelectOpen(true);
                }}
                onBlur={() => setLanguageSelectOpen(false)}
                tabIndex={0}
              >
                <img src={global} alt="다국어지원" />
                {languageSelectOpen && !isSmallType && (
                  <ul>
                    <li
                      onClick={() =>
                        window.open('https://jp.ondoc.co.kr', '_blank')
                      }
                    >
                      <span>日本語</span>
                    </li>
                  </ul>
                )}
              </div> */}
            </div>
            <div className={styles.navigation}>
              <ul onMouseLeave={(e) => handleNavigationBarHide(e)}>
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => navigate(`/companyInfo`)}
                >
                  <span>온닥</span>
                </li>
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => navigate(`/program-list`)}
                >
                  <span>병원진료</span>
                </li>
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => navigate('/productList')}
                >
                  <span>상품</span>
                </li>
                <li
                  className={`${styles.subMenuWrap} ${styles.dietMenuWrap}`}
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => {
                    navigate('/productDetail/136');
                  }}
                >
                  <span>다이어트 프로그램</span>
                </li>
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => {
                    if (!loginConfirmRurl('/myWeight')) return;
                    navigate('/myWeight');
                  }}
                >
                  <span>챌린지</span>
                </li>
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => {
                    if (!isLogin) navigate('/event');
                    else navigate('/review/all');
                  }}
                >
                  <span>커뮤니티</span>
                  <ul>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/notice');
                      }}
                    >
                      공지사항
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/event');
                      }}
                    >
                      이벤트
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!loginConfirmRurl('/review/all')) return;
                        navigate('/review/all');
                      }}
                    >
                      상품 후기
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/faq');
                      }}
                    >
                      FAQ
                    </li>
                  </ul>
                </li>
                <div ref={movingBarRef} className={styles.movingBar}></div>
              </ul>
            </div>
            {/* <KakaoBanner /> */}
          </div>
          {searchOpen && <SearchModal setOpen={setSearchOpen} />}
        </>
      ) : (
        <>
          <div
            className={`${styles.mContainer} ${
              !props.adBanner ? styles.adBanner : ''
            }`}
          >
            <img
              src={logo}
              alt="logo"
              className={styles.logo}
              onClick={() => navigate('/')}
            />
            <div className={styles.btns}>
              {/* <img src={search} alt="search" className={styles.search} /> */}
              <img
                src={basket}
                alt="basket"
                className={styles.basket}
                onClick={() => navigate('/basket')}
              />
              {/* <img
                src={global}
                alt="언어선택"
                onClick={() => {
                  if (languageSelectOpen) setLanguageSelectOpen(false);
                  else setLanguageSelectOpen(true);
                }}
              /> */}
              <img
                src={hamburger}
                alt="hamburger"
                className={styles.hamburger}
                onClick={() => setMobileSideOpen(true)}
              />
              {languageSelectOpen && (
                <ul>
                  <li
                    onClick={() =>
                      window.open('https://jp.ondoc.co.kr', '_blank')
                    }
                  >
                    <span>日本語</span>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className={styles.mHeighter}></div>
          {mobileSideOpen && <SideMenuMobile isOpen={setMobileSideOpen} />}
        </>
      )}
    </>
  );
}
export default Header2;

import mainFaqData from '../../assets/data/mainFaq.json';
import doctorData from '../../assets/data/doctor.json';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import HeaderMain from '../../components/HeaderMain/HeaderMain';
import styles from './HomeMobile.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import { useEffect, useState } from 'react';
import leesoyoungMobile from '../../assets/doctors/leesoyoungMobile.png';
import youjiyouMobile from '../../assets/doctors/youjiyouMobile.png';
import kimmiriMobile from '../../assets/doctors/kimmiriMobile.png';
import doctorDrop from '../../assets/home/doctorDrop.png';
import axiosClient from '../../libs/axiosClient';
import { useQuery } from 'react-query';
import { IFaqType } from '../../pages/Faq/Faq';
import rightArrow from '../../assets/rightArrow.png';
import MainFaqFrameMobile from '../../mobileComponents/MainFaqFrameMobile/MainFaqFrameMobile';
import MainReviewFrame from '../../components/MainReviewFrame/MainReviewFrame';
import { IReviewDataType } from '../../pages/MyReview/MyReview';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { loginCheck } from '../../auth/auth';
import ReviewFilter from '../../components/ReviewFilter/ReviewFilter';
import banner02 from '../../assets/bannerImage02.png';
import dietbannerMobile from '../../assets/home/dietbannerMobile.png';
import ondocGlobal from '../../assets/home/ondocGlobalMobile.png';
import ondocLegit from '../../assets/home/ondocLegitMobile.png';
import ondocInfo1 from '../../assets/home/ondocInfo1.png';
import ondocInfo2 from '../../assets/home/ondocInfo2.png';
import ondocInfo3 from '../../assets/home/ondocInfo3.png';
import country01 from '../../assets/country/country1.png';
import country02 from '../../assets/country/country2.png';
import country03 from '../../assets/country/country3.png';
import country04 from '../../assets/country/country4.png';
import star from '../../assets/star.png';
import review from '../../assets/review.png';
import { IProductListType } from '../../pages/ProductList/ProductList';
import banner3 from '../../assets/banner/mobileMainBanner03.jpg';
import banner4 from '../../assets/banner/mobileMainBanner04.jpg';
import banner5 from '../../assets/banner/mobileMainBanner05.jpg';
import banner6 from '../../assets/banner/mobileMainBanner06.jpg';
import banner7 from '../../assets/banner/mobileMainBanner07.jpg';
import LazyLoad from 'react-lazy-load';
import { IAdLinkDataType } from '../../pages/Home/Home';
import ADLink from '../../components/ADLink/ADLink';
import { IBannerType } from '../../components/MainBanner/MainBanner';
import { checkRequired } from '../../common/checkRequired';
import getChallengeData from '../../common/getChallengeData';
import ChallengeFrame from '../../components/ChallengeFrame/ChallengeFrame';
import DoctorFrame from '../../components/DoctorFrame/DoctorFrame';
import Popup from '../../components/Popup/Popup';
import { useCookies } from 'react-cookie';
import { IOptionType } from '../../pages/ProductDetail/ProductDetail';
const countryList = [country01, country02, country03, country04];

const mainFaqList = mainFaqData;
const doctorList = doctorData;
function HomeMobile() {
  const location = useLocation();
  const navigate = useNavigate();
  //배너
  const [slideList, setSlideList] = useState<IBannerType[]>([]);
  useEffect(() => {
    setSlideList(() => {
      let list: IBannerType[] = [
        { image: banner7, url: 'productDetail/136' },
        { image: banner6, url: 'productDetail/116' },
        { image: banner3, url: 'event/22' },
        { image: banner5, url: 'event/24' },
        { image: banner4, url: 'event/23' },
      ];
      return [...list];
    });
  }, []);
  const [activeIdx, setActiveIdx] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore>();
  //배너

  const [firstOptionList, setFirstOptionList] = useState<IOptionType[]>();
  const [programPrice, setProgramPrice] = useState([
    {
      price: 512000,
      salePrice: 380000,
    },
    {
      price: 210000,
      salePrice: 0,
      isMonthly: true,
    },
    {
      price: 72000,
      salePrice: 0,
    },
    {
      price: '72,000원/월',
      salePrice: 0,
    },
  ]);

  useEffect(() => {
    axiosClient.get(`/data/product/detail/136/options`).then((res) => {
      setFirstOptionList(res.data.data);
    });
  }, []);

  //상품
  const { data: productList } = useQuery<IProductListType[]>(
    ['productList', 'all'],
    async () => {
      let queryString = '';
      let ids = JSON.parse(
        sessionStorage.getItem('product_ids') || JSON.stringify([]),
      );
      if (ids.length) {
        queryString = 'add_products=';
        ids.map((id: number, idx: number) => {
          queryString += `${id}${idx !== ids.length - 1 ? ',' : ''}`;
        });
      }
      return await axiosClient
        .get(`/data/product/list?${queryString}`)
        .then((res) => res.data.data);
    },
    {
      staleTime: 60000 * 60 * 24,
    },
  );
  //상품
  //프로세스
  const [processSliderIdx, setProcessSliderIdx] = useState(0);
  //프로세스
  //faq
  // const { data: faqListQuery } = useQuery<{
  //   data: IFaqType[];
  //   faq_type: {
  //     [key: number]: string;
  //   };
  // }>('faq', () => axiosClient.get('/data/faq').then((res) => res.data.data), {
  //   staleTime: 60000 * 60 * 24, //1일 캐싱
  // });
  //faq
  //review
  // const [reviewData, setReviewData] = useState<IReviewDataType[]>([]);
  // useEffect(() => {
  //   axiosClient
  //     .get('/data/product_talk/list?&talk_type=상품평가&limit=5&order_by=star')
  //     .then((res) => setReviewData(res.data.data));
  // }, []);
  const { data: reviewData } = useQuery<IReviewDataType[]>(
    ['main', 'photo_review'],
    () =>
      axiosClient
        .get(
          '/data/product_talk/photo-review-list?&talk_type=상품평가&limit=5&order_by=star',
        )
        .then((res) => res.data.data),
    {
      staleTime: 60000 * 60,
    },
  );
  //review
  //doctor
  const [doctorSliderIdx, setDoctorSliderIdx] = useState(0);
  //doctor

  //기존 상품 url접속시 대응
  useEffect(() => {
    let pn = new URLSearchParams(location.search).get('pn');
    if (!pn) return;
    if (pn == 'product.view') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=N&code=${new URLSearchParams(
            location.search,
          ).get('pcode')}`,
        )
        .then((res) => navigate(res.data.data.url));
    } else if (pn == 'blind_gate') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=Y&code=${new URLSearchParams(
            location.search,
          ).get('bc')}`,
        )
        .then((res) => navigate(res.data.data.url));
    }
  }, []);
  //기존 상품 url접속시 대응
  const [adLinkOpen, setAdLinkOpen] = useState(true);
  const [adLinkData, setAdLinkData] = useState<IAdLinkDataType>();
  // useEffect(() => {
  //   axiosClient.get('/data/product/promotional_product').then((res) => {
  //     if (!Object.keys(res.data.data).length) return;
  //     setAdLinkData(res.data.data);
  //   });
  // }, []);

  const [hasCookie, setHasCookie] = useState(true);
  const [cookies, setCookies] = useCookies();

  useEffect(() => {
    if (cookies['week-close']) setHasCookie(true);
    else setHasCookie(false);
  }, []);

  return (
    <>
      {adLinkOpen && adLinkData && (
        <ADLink
          productId={adLinkData.id}
          price={adLinkData.price}
          discountedPrice={adLinkData.sale_price}
          closer={setAdLinkOpen}
        />
      )}
      {/* {!hasCookie && <Popup />} */}
      <div>
        {/* <Header /> */}
        <HeaderMain />
        <div className={styles.bannerSliderWrap}>
          <Swiper
            onSwiper={setSwiper}
            slidesPerView={1}
            spaceBetween={0}
            className={styles.bannerSlider}
            onSlideChange={(e) => setActiveIdx(e.activeIndex)}
          >
            {slideList.map((slide, idx) => (
              <SwiperSlide
                key={idx}
                onClick={() => {
                  if (slide.url) navigate(slide.url);
                }}
              >
                <img
                  src={slide.image}
                  alt="banner"
                  style={{ cursor: slide.url ? 'pointer' : 'unset' }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <ul className={styles.paginationWrap}>
            {slideList.map((banner, idx) => (
              <li
                key={idx}
                onClick={() => swiper?.slideTo(idx, 200)}
                className={idx == activeIdx ? styles.hit : ''}
              ></li>
            ))}
          </ul>
        </div>
        {/* <div className={styles.processInfoWrap}>
          <h3>비대면 처방 프로세스</h3>
          <p>빠르고 안전한, 쉽고 편리한 진료 서비스</p>
          <ul>
            <li># 비대면서비스</li>
            <li># 믿고받는진료</li>
            <li># 가벼운 증상부터 만성질환까지</li>
          </ul>
          <div className={styles.processSliderWrap}>
            <Swiper
              slidesPerView={1}
              spaceBetween={21}
              className={styles.processSlider}
              onSlideChange={(e) => setProcessSliderIdx(e.activeIndex)}
            >
              <SwiperSlide className={styles.processSlide}>
                <img src={process01} alt="process" />
                <div className={styles.stage}>
                  <h4>문진표 작성</h4>
                  <p>제품 선택 후 문진표 작성</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process02} alt="process" />
                <div className={styles.stage}>
                  <h4>결제 및 접수</h4>
                  <p>결제 완료 후 1-3일 이내 담당의사 접수</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process03} alt="process" />
                <div className={styles.stage}>
                  <h4>전화처방</h4>
                  <p>담당 의사로부터 전화 진료 시작</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process04} alt="process" />
                <div className={styles.stage}>
                  <h4>당일발송</h4>
                  <p>오후 12시 이내 처방 완료건 당일 배송</p>
                </div>
              </SwiperSlide>
            </Swiper>
            <ul className={styles.processSliderPagination}>
              {(() => {
                let list = [0, 1, 2, 3];
                return list.map((val) => (
                  <li
                    key={val}
                    className={processSliderIdx == val ? styles.hit : ''}
                  >
                    <div></div>
                  </li>
                ));
              })()}
            </ul>
          </div>
        </div> */}
        {/* <div className={styles.worldPlatform}>
          <h3>
            온닥은 해외 원격 진료를 통해
            <br />
            해외 각국에 한약을 처방하고 있습니다.
          </h3>
          <ul>
            {countryList.map((country, idx) => (
              <li key={idx}>
                <img src={country} />
              </li>
            ))}
          </ul>
          <p>
            미국, 태국, 호주, 아랍 등 총 6개 국가
            <br />
            해외 원격 진료 서비스 지원
          </p>
        </div> */}
        {/* <div className={styles.prescriptionProductWrap}>
          <h3 onClick={() => navigate(`/productList?cuid=487`)}>
            <span>처방의약품</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>온닥 비대면 처방의약품</span>
          <Swiper
            slidesPerView={1.1}
            spaceBetween={10}
            className={styles.prescriptionProductSlider}
          >
            {productList
              ?.filter((product) => product.is_prescription == 1)
              .map((product) => (
                <SwiperSlide
                  key={product.product_id}
                  className={styles.prescriptionProductSlide}
                  onClick={() =>
                    navigate(`/productDetail/${product.product_id}`)
                  }
                >
                  <img
                    src={product.list_image + '?w=530'}
                    alt="productThumbnail"
                  />
                  <div className={styles.productInfoWrap}>
                    <h3>{product.title}</h3>
                    <ul className={styles.hashtagList}>
                      {product.hashtag &&
                        product.hashtag
                          .split(',')
                          .map((hashtag, idx) => (
                            <li key={idx}># {hashtag}</li>
                          ))}
                    </ul>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div> */}
        {/* <section className={styles.challengeWrap}>
          <div className={styles.sectionBanner}></div>
          <div className={styles.sectionContent}>
            <h2>온닥 챌린지</h2>
            <p>다양한 챌린지 참여하고 혜택 받자!</p>
            <Swiper slidesPerView={1.1} spaceBetween={10} centeredSlides>
              {getChallengeData().map((challenge, idx) => (
                <SwiperSlide key={idx}>
                  <ChallengeFrame
                    challenge={challenge}
                    width={333}
                    height={400}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <button onClick={() => navigate('/challenge')}>
              모든 챌린지 보기
            </button>
          </div>
        </section> */}
        <div className={styles.normalProductWrap}>
          <h3 onClick={() => navigate(`/productList`)}>
            <span>건강 식품</span>
            <div className={styles.directWrap}>
              <span>전체보기</span>
              <img src={rightArrow} alt="rightArrow" />
            </div>
          </h3>
          <Swiper slidesPerView={1.7} spaceBetween={10}>
            {productList &&
              productList
                ?.filter((product) => product.is_prescription === 0)
                .map((product, idx) => (
                  <SwiperSlide>
                    <div
                      className={styles.productSlideWrap}
                      onClick={() =>
                        navigate(`/productDetail/${product.product_id}`)
                      }
                    >
                      <div className={styles.productThumbnailWrap}>
                        <img
                          src={product.list_image + '?w=530'}
                          alt="productThumbnail"
                        />
                      </div>
                      <div className={styles.productInfoWrap}>
                        <div className={styles.productInfoLeft}>
                          <p>{product.title}</p>
                          <div className={styles.productInfoPrice}>
                            <span>
                              {product.discount_price.toLocaleString()}원
                            </span>
                            {product.price !== 0 && (
                              <span>{product.price.toLocaleString()}원</span>
                            )}
                          </div>
                          <div className={styles.productInfoScore}>
                            <img src={review} alt="review" />
                            <span>
                              {product.review_score_avg?.review_total_count.toLocaleString()}
                            </span>
                          </div>
                          {/* {props.allReviewData
                    ? Math.floor(
                        (props.allReviewData.review_point_total_avg / 2) * 10,
                      ) / 10
                    : 0} */}
                        </div>
                        <div className={styles.productInfoRight}></div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
          </Swiper>
          {/* <ul className={styles.normalProductList}>
            {productList
              ?.filter((product) => product.is_prescription == 0)
              .filter((product, idx) => idx == 0)
              .map((product) => (
                <li
                  key={product.product_id}
                  onClick={() =>
                    navigate(`/productDetail/${product.product_id}`)
                  }
                >
                  <div className={styles.productThumbnailWrap}>
                    <img
                      src={product.list_image + '?w=530'}
                      alt="productThumbnail"
                    />
                  </div>
                  <div className={styles.productInfoWrap}>
                    <h3>{product.title}</h3>
                    <ul className={styles.hashtagList}>
                      {product.hashtag &&
                        product.hashtag
                          .split(',')
                          .map((hashtag, idx) => <li key={idx}>{hashtag}</li>)}
                    </ul>
                  </div>
                </li>
              ))}
          </ul> */}
        </div>
        {/* <div className={styles.allProductList}>
          <h3>
            <span>ONDOC PRODUCT</span>
          </h3>
          <span>내원없이 간편하게 받자!</span>
          <ul className={styles.productListWrap}>
            {productList
              ?.filter((product, idx) => idx < 4)
              .map((product) => (
                <li
                  key={product.product_id}
                  onClick={() =>
                    navigate(`/productDetail/${product.product_id}`)
                  }
                >
                  <div className={styles.productThumbnailWrap}>
                    <img
                      src={product.list_image + '?w=530'}
                      alt="productThumbnail"
                    />
                  </div>
                  <div className={styles.productInfoWrap}>
                    <h3>{product.title}</h3>
                    {product.discount_rate !== 0 && (
                      <span className={styles.discountRate}>
                        {product.discount_rate}%
                      </span>
                    )}
                    <span className={styles.price}>
                      {product.discount_price.toLocaleString()}원
                    </span>
                    <div className={styles.reviewScore}>
                      <img src={star} alt="star" />
                      <span>
                        {Math.floor(
                          (product.review_score_avg.review_point_total_avg /
                            2) *
                            10,
                        ) / 10}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          {productList && productList.length > 4 && (
            <button onClick={() => navigate(`/productList`)}>
              모든 제품 보기
            </button>
          )}
        </div> */}
        <div className={styles.dietBannerWrap}>
          <img src={dietbannerMobile} alt="dietbanner" />
        </div>
        <div className={styles.dietProgramWrap}>
          <div className={styles.titleWrap}>
            <span>온닥 다이어트 프로그램</span>
          </div>
          <div className={styles.dietSwiperWrap}>
            <Swiper
              slidesPerView={1.5}
              spaceBetween={10}
              // slidesOffsetAfter={21}
              className={styles.reviewSlider}
              breakpoints={{ 700: { slidesPerView: 2.8 } }}
            >
              {firstOptionList &&
                firstOptionList.map((item, idx) => (
                  <SwiperSlide>
                    <div
                      className={styles.dietSliderWrap}
                      onClick={() => {
                        navigate('/productDetail/136', {
                          state: {
                            programId: idx,
                          },
                        });
                      }}
                    >
                      <div className={styles.dietImgWrap}>
                        <img
                          src={`https://img.ondoc.co.kr/upfiles/options/option${
                            idx + 1
                          }.png`}
                          alt="program"
                        />
                      </div>
                      <div className={styles.dietContentWrap}>
                        <span>{item.po_poptionname}</span>
                        <div className={styles.priceWrap}>
                          <span>
                            {idx === 1
                              ? '210,000원~/월'
                              : programPrice[idx].salePrice
                              ? programPrice[idx].salePrice.toLocaleString()
                              : programPrice[idx].price.toLocaleString()}
                            {idx !== firstOptionList.length - 1 && '원'}
                          </span>
                          <span>
                            {programPrice[idx].salePrice !== 0 &&
                              programPrice[idx].price.toLocaleString() + '원'}
                          </span>
                        </div>
                        {/* <div className={styles.starWrap}>
                          <img src={star} alt="star" />
                          <span>{item.star}</span>
                        </div> */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
        <div className={styles.reviewWrap}>
          <h3 onClick={() => navigate(`/review/all`)}>
            <span>실시간 후기</span>
            <div className={styles.directWrap}>
              <span>전체보기</span>
              <img src={rightArrow} alt="rightArrow" />
            </div>
          </h3>
          <div className={styles.reviewSliderWrap}>
            <LazyLoad>
              <Swiper
                slidesPerView={1.5}
                spaceBetween={10}
                slidesOffsetAfter={21}
                className={styles.reviewSlider}
                breakpoints={{ 700: { slidesPerView: 2.8 } }}
              >
                {reviewData
                  ?.filter((review) => review.product?.list_image)
                  .map((review) => (
                  <SwiperSlide key={review.id}>
                    <MainReviewFrame review={review} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </LazyLoad>
            {!loginCheck() && <ReviewFilter borderRadius={0} />}
          </div>
        </div>
        <div className={styles.faqWrap}>
          <h3 onClick={() => navigate(`/faq`)}>
            <span>자주 묻는 질문</span>
            <div className={styles.directWrap}>
              <span>전체보기</span>
              <img src={rightArrow} alt="rightArrow" />
            </div>
          </h3>
          <ul>
            {mainFaqList.map((faq, idx) => (
              <MainFaqFrameMobile faq={faq} key={idx} />
            ))}
          </ul>
        </div>
        <div className={styles.globalLegitScriptWrap}>
          <Swiper slidesPerView={1.07} spaceBetween={13} slidesOffsetAfter={21}>
            <SwiperSlide>
              <img src={ondocGlobal} alt="ondocGlobal" />
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.legitWrap}>
                <img src={ondocLegit} alt="ondocLegit" />
                <img
                  className={styles.legitLogo}
                  src="	https://static.legitscript.com/seals/14145209.png"
                  alt="legit"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* <div className={styles.legitScript}>
          <img src={america} alt="america" />
          <div>
            <img
              src="	https://static.legitscript.com/seals/14145209.png"
              alt="legit"
            />
            <p>
              온닥은 미국 legitScript 로 부터
              <br />
              원격 의료 인증을 받았습니다.
            </p>
            <p>
              Ondoc started with US legitScript
              <br />
              contactless medical certification has been obtained.
            </p>
          </div>
        </div> */}
        <div className={styles.ondocInfoWrap}>
          <h3>
            <span>온닥 세가지 원칙</span>
          </h3>
          <ul>
            <li>
              <p>원칙 1.</p>
              <h3>
                다이어트 프로그램을 통해
                <br />
                쉽고 빠르게
              </h3>
              <p>
                온닥 다이어트 프로그램은 간편하면서도 효과적인
                <br /> 방법으로 몸매를 빠르게 조절하는데 중점을 두고
                <br /> 있습니다. 일상 생활 속에서 맞춤 처방된 약재를
                <br /> 통해 건강을 유지하면서 다이어트의 효과를
                <br /> 최대로 느껴보세요.
              </p>
              <img src={ondocInfo1} alt="image" />
            </li>
            <li>
              <p>원칙 2.</p>
              <h3>
                원격 진료를
                <br />
                간편하고 정확하게
              </h3>
              <p>
                온닥은 내원없이 간편하게 받아보는 원격진료서비스
                <br />
                입니다. 쉽고 편한 의료 서비스를 제공하여 언제
                <br /> 어디서나 정확한 진료를 받아볼 수 있습니다.
              </p>
              <img src={ondocInfo2} alt="image" />
            </li>
            <li>
              <p>원칙 3.</p>
              <h3>
                정성과 과학으로
                <br />
                조제합니다.
              </h3>
              <p>
                온닥의 의료진은 검증된 약재와 맞춤 처방으로
                <br /> 안전한 효과를 추구하며 식약처로부터 의약용품
                <br /> 인증을 받은 약재만을 사용하여 제조합니다.
              </p>
              <img src={ondocInfo3} alt="image" />
            </li>
          </ul>
        </div>
        <div className={styles.doctorInfoWrap}>
          <h3>온닥 다이어트 의료진</h3>
          <div className={styles.doctorSliderWrap}>
            {doctorList?.map((doctor, idx) => (
              <DoctorFrame doctor={doctor} key={idx} />
            ))}

            {/* <Swiper
              slidesPerView={1}
              spaceBetween={21}
              className={styles.doctorSlider}
              onSlideChange={(e) => setDoctorSliderIdx(e.activeIndex)}
            > */}
            {/* <SwiperSlide className={styles.doctorSlide}>
              <div className={styles.doctorImageWrap}>
                <img src={doctor01} alt="process" />
              </div>
              <div className={styles.doctorInfo}>
                <h4>손영훈 한의사</h4>
                <span>Son Younghoon</span>
                <ul>
                  <li>원광대학교 한의과대학 졸업</li>
                  <li>보건복지부 장관상 수상</li>
                  <li>서울시 체육회장상 수상</li>
                  <li>서울시의회 의장상 수상</li>
                  <li>대한한방해외의료봉사단 부단장</li>
                  <li>대한한의사협회 국제위원</li>
                </ul>
              </div>
            </SwiperSlide> */}
            {/* <SwiperSlide className={styles.doctorSlide}>
                <div className={styles.doctorImageWrap}>
                  <img src={doctor02} alt="process" />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>이소영 한의사</h4>
                  <span>Lee Soyoung</span>
                  <ul>
                    <li>동의방약학회 정회원</li>
                    <li>한방비만학회 준회원</li>
                    <li>심리상담사 1급 심리분석사 1급</li>
                    <li>원광대학교 한의대학졸업</li>
                    <li>
                      E-BOOK {'<'}습담제거로 뱃살 뺀 이야기
                      {'>'} 저자
                    </li>
                    <li>유튜브 ‘당뇨스쿨’ 운영자</li>
                    <li>당뇨 쇼핑몰 '당몰' 자문위원</li>
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.doctorSlide}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={youjiyou}
                    alt="process"
                    style={{ maxWidth: '151px' }}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>유지유 한의사</h4>
                  <span>Yoo jiyou</span>
                  <ul>
                    <li>원광대학교 한의과대학 졸업</li>
                    <li>신농씨 학회 정회원</li>
                    <li>MPS(근막이완침술) 교육 이사</li>
                    <li>척추관절 추나교육 이수</li>
                    <li>동의보감 병인론 연구모임</li>
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.doctorSlide}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={kimmiri}
                    alt="process"
                    style={{
                      maxWidth: '183px',
                      objectPosition: 'top',
                    }}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>김미리 한의사</h4>
                  <span>Kim miri</span>
                  <ul>
                    <li>세명대학교 한의과대학 졸업</li>
                    <li>강남위담한방병원 일반수련의 수료</li>
                    <li>대한한의학회 정회원</li>
                    <li>대한담적한의학회 회원</li>
                    <li>대한한방비만학회 회원</li>
                  </ul>
                </div>
              </SwiperSlide> */}
            {/* <SwiperSlide className={styles.doctorSlide}>
              <div className={styles.doctorImageWrap}>
                <img src={doctor03} alt="process" />
              </div>
              <div className={styles.doctorInfo}>
                <h4>박나라 한의사</h4>
                <span>Park Nara</span>
                <ul>
                  <li>대전대학교 한의과대학 졸업</li>
                  <li>유튜브 ‘온라인한의사’ 운영진</li>
                  <li>한의 원격의료 연구회 회원</li>
                </ul>
              </div>
            </SwiperSlide> */}
            {/* <SwiperSlide className={styles.doctorSlide}>
              <div className={styles.doctorImageWrap}>
                <img src={doctor04} alt="process" className={styles.bigImage} />
              </div>
              <div className={styles.doctorInfo}>
                <h4>김운정 한의사</h4>
                <span>Kim Unjung</span>
                <ul>
                  <li>세명대학교 한의학과 졸업</li>
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.doctorSlide}>
              <div className={styles.doctorImageWrap}>
                <img src={doctor05} alt="process" className={styles.bigImage} />
              </div>
              <div className={styles.doctorInfo}>
                <h4>김지현 한의사</h4>
                <span>Kim Jihyun</span>
                <ul>
                  <li>세명대학교 한의과대학 한의학과 졸업</li>
                  <li>분당자생한방병원 일반수련의 수료</li>
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.doctorSlide}>
              <div className={styles.doctorImageWrap}>
                <img src={doctor06} alt="process" className={styles.bigImage} />
              </div>
              <div className={styles.doctorInfo}>
                <h4>박은영 한의사</h4>
                <span>Park Eunyoung</span>
                <ul>
                  <li>동국대학교 한의과대학 졸업</li>
                  <li>보건복지부 불안장애 한의표준</li>
                  <li>임상진료 지침 연구원</li>
                  <li>2020~2022 하이닥-네이버IN상담 한의사</li>
                  <li>
                    {'<'}비만당뇨 한방치료사례집{'>'} 저자
                  </li>
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.doctorSlide}>
              <div className={styles.doctorImageWrap}>
                <img src={doctor07} alt="process" className={styles.bigImage} />
              </div>
              <div className={styles.doctorInfo}>
                <h4>장효두 한의사</h4>
                <span>Jang Hyodoo</span>
                <ul>
                  <li>경희대학교 한의과대학 한의학과 졸업</li>
                  <li>경희의료원 임상과정 수료</li>
                  <li>강동경희대병원 임상과정 수료</li>
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.doctorSlide}>
              <div className={styles.doctorImageWrap}>
                <img src={doctor08} alt="process" className={styles.bigImage} />
              </div>
              <div className={styles.doctorInfo}>
                <h4>전유경 한의사</h4>
                <span>Jeon Youkyung</span>
                <ul>
                  <li>대한한의학회 정회원</li>
                  <li>한방비만학회 준회원</li>
                  <li>대한약침학회 준회원</li>
                </ul>
              </div>
            </SwiperSlide> */}
            {/* </Swiper> */}
            {/* <ul className={styles.doctorSliderPagination}>
              {(() => {
                let list = [0, 1, 2];
                return list.map((val) => (
                  <li
                    key={val}
                    className={doctorSliderIdx == val ? styles.hit : ''}
                  >
                    <div></div>
                  </li>
                ));
              })()}
            </ul> */}
          </div>
        </div>
        <div className={styles.ondocService}>
          <div className={styles.textWrap}>
            <p>
              온닥은 쉽고 편리한
              <br />
              진료 서비스를 제공합니다.
            </p>
            <p>
              온닥은 내원 없이 간편하게 받아보는 비대면 진료 서비스로
              <br />
              온닥의 의료진과 함께 가벼운 증상부터 만성질환까지
              <br />
              의료 서비스 이용에 어려움을 겪는 분들에게 빠르고 <br /> 안전한,
              쉽고 편리한 진료 서비스를 제공합니다.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default HomeMobile;
